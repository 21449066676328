import React, { useEffect, useContext } from "react";
import axios from "axios";
import { PlayerNationContext } from "../context/PlayerNationContext";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  table: {
    minWidth: 100,
    maxWidth: "100%",
  },
  tablecell: {
    padding: "5px 0",
  },
  tableleftcell: {
    padding: "5px 15px",
  },
});

const PlayerNationSumTable = () => {
  const [playerstate, setPlayerState] = useContext(PlayerNationContext);
  const classes = useStyles();

  useEffect(() => {
    const getData = async () => {
      let params = new URLSearchParams();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/getDataPlayerNationSum.php`,
        params
      );
      // 国名編集
      let res = response.data.map((row) => {
        const target = playerstate.nationlist.find((nation) => {
          return nation.code === row["nation"];
        });
        if (target !== null && target !== undefined) {
          row["nation"] = target.name;
        }
        // if (row["nation"] === "ドイツ") {
        //   row["GER1"] = null;
        // }
        return row;
      });

      res.sort((a, b) => -(a.CNT_TOTAL - b.CNT_TOTAL));
      res.splice(playerstate.dispcountsum);

      setPlayerState((playerstate) => ({
        ...playerstate,
        playersumlist: res,
      }));
    };
    getData();
  }, [playerstate.dispcountsum, playerstate.nationlist, setPlayerState]);

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableleftcell}>国籍</TableCell>
            <TableCell className={classes.tablecell}>ENG1</TableCell>
            <TableCell className={classes.tablecell}>GER1</TableCell>
            <TableCell className={classes.tablecell}>ESP1</TableCell>
            <TableCell className={classes.tablecell}>ITA1</TableCell>
            <TableCell className={classes.tablecell}>合計</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {playerstate.playersumlist.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tableleftcell}>{row.nation}</TableCell>
              <TableCell className={classes.tablecell}>{row.ENG1}</TableCell>
              <TableCell className={classes.tablecell}>{row.GER1}</TableCell>
              <TableCell className={classes.tablecell}>{row.ESP1}</TableCell>
              <TableCell className={classes.tablecell}>{row.ITA1}</TableCell>
              <TableCell className={classes.tablecell}>
                {row.CNT_TOTAL}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlayerNationSumTable;
