import React, { useEffect, useRef, useState } from "react";
import Chartjs from "chart.js";

const chartConfig = {
  type: "line",
  data: {
    labels: [
      "09-10",
      "10-11",
      "11-12",
      "12-13",
      "13-14",
      "14-15",
      "15-16",
      "16-17",
      "17-18",
      "18-19",
      "19-20",
    ],
    datasets: [
      {
        label: "ラ・リーガ",
        data: [2.71, 2.74, 2.76, 2.87, 2.75, 2.66, 2.74, 2.84, 2.69, 2.59, 2.48],
        backgroundColor: "rgba(255,204, 51, 0.2)",
        borderColor: "rgba(255,204, 51, 1)",
        borderWidth: 2,
        hoverBackgroundColor: "rgba(255,204, 51,0.4)",
        hoverBorderColor: "rgba(255,204, 51,1)",
        fill: false,
      },
      {
        label: "ブンデスリーガ",
        data: [2.83, 2.92, 2.86, 2.93, 3.16, 2.75, 2.83, 2.72, 2.79, 3.18, 3.21],
        backgroundColor: "rgba(102,153,255, 0.2)",
        borderColor: "rgba(102,153,255, 1)",
        borderWidth: 2,
        hoverBackgroundColor: "rgba(102,153,255,0.4)",
        hoverBorderColor: "rgba(102,153,255,1)",
        fill: false,
      },
      {
        label: "プレミアリーグ",
        data: [2.77, 2.8, 2.81, 2.8, 2.77, 2.57, 2.7, 2.8, 2.68, 2.82, 2.72],
        backgroundColor: "rgba(204,153,255, 0.2)",
        borderColor: "rgba(204,153,255, 1)",
        borderWidth: 2,
        hoverBackgroundColor: "rgba(204,153,255,0.4)",
        hoverBorderColor: "rgba(204,153,255,1)",
        fill: false,
      },
      {
        label: "セリエA",
        data: [2.61, 2.51, 2.56, 2.63, 2.72, 2.69, 2.58, 2.9, 2.68, 2.68, 3.04],
        backgroundColor: "rgba(255,153,153, 0.2)",
        borderColor: "rgba(255,153,153, 1)",
        borderWidth: 2,
        hoverBackgroundColor: "rgba(255,153,153,0.4)",
        hoverBorderColor: "rgba(255,153,153,1)",
        fill: false,
      },
      {
        label: "J1",
        data: [2.66, 2.84, 2.79, 2.87, 2.53, 2.68, 2.63, 2.59, 2.66, 2.6],
        backgroundColor: "rgba(102,204,102, 0.2)",
        borderColor: "rgba(102,204,102, 1)",
        borderWidth: 2,
        hoverBackgroundColor: "rgba(102,204,102,0.4)",
        hoverBorderColor: "rgba(102,204,102,1)",
        fill: false,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "top",
    },
    tooltips: {
      mode: "index",
      intersect: true,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
          },
        },
      ],
    },
  },
};

const ScoreChart = () => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  return (
    <div style={{ position: "relative", height: "300px", width: "100%" }}>
      <canvas ref={chartContainer} />
    </div>
  );
};

export default ScoreChart;
