import React from "react";
import JSeason from "./season/JSeason";
import JTeam from "./team/JTeam";
import JShoot from "./shoot/JShoot";
import EUShoot from "./shootEU/EUTeam";
import Score from "./score/Score";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

const JLeague = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="primary">Aggressive(シュート多い)＆Beautiful(反則少ない)チームを探して視聴の参考に！！</Typography>
        </Grid>
        <Grid item md={7} xs={12}>
          <Box mb={4}>
            <Paper className={classes.paper}>
              <JSeason />
            </Paper>
          </Box>
          <Box mb={4}>
            <Paper className={classes.paper}>
              <JTeam />
            </Paper>
          </Box>
          <Box mb={4}>
            <Paper className={classes.paper}>
              <JShoot />
            </Paper>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box mb={4}>
            <Paper className={classes.paper}>
              <EUShoot />
            </Paper>
          </Box>
          <Box mb={4}>
            <Paper className={classes.paper}>
              <Score />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default JLeague;
