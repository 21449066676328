import React, { useEffect, useRef, useState, useContext } from "react";
import Chartjs from "chart.js";
import { WordContext } from "../context/WordContext";

const chartConfig = {
  type: "horizontalBar",
  data: {
    labels: [],
    datasets: [
      {
        label: "回数",
        data: [],
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(54, 162, 235,0.4)",
        hoverBorderColor: "rgba(54, 162, 235,1)",
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
    },
    tooltips: {
      mode: "index",
      intersect: true,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

const WordBar = () => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [style, setStyle] = useState({
    position: "relative",
    height: "300px",
    width: "100",
  });
  const [wordstate] = useContext(WordContext);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  useEffect(() => {
    if (chartInstance !== null) {
      chartInstance.data.labels = wordstate.wordlist.map((row) =>
        row["word"].toLowerCase()
      );
      chartInstance.data.datasets[0].data = wordstate.wordlist.map(
        (row) => row["cnt"]
      );
      chartInstance.update();
    }
    if (wordstate.dispcount === 10) {
      setStyle((style) => ({
        ...style,
        height: "300px",
      }));
    } else if (wordstate.dispcount === 20) {
      setStyle((style) => ({
        ...style,
        height: "600px",
      }));
    }
  }, [wordstate.wordlist, wordstate.dispcount, chartInstance]);

  return (
    <div style={style}>
      <canvas ref={chartContainer} />
    </div>
  );
};

export default WordBar;
