import React, { createContext, useState } from "react";

// const initialState = {
//   division: "J1"
// };

export const JTeamDivisionContext = createContext("J1");

export const JTeamDivisionContextProvider = ({ children }) => {
  const [division, setDivision] = useState("J1");

  return (
    <JTeamDivisionContext.Provider value={[division, setDivision]}>
      {children}
    </JTeamDivisionContext.Provider>
  );
};
