import React, { useContext } from "react";
import { LanguageContext } from "./context/LanguageContext";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  table: {
    minWidth: 100,
  },
  tablecell: {
    padding: "5px 5px",
  },
});

const LanguageTable = () => {
  const [state] = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tablecell}>意味</TableCell>
            <TableCell className={classes.tablecell}>スペイン語</TableCell>
            <TableCell className={classes.tablecell}>イタリア語</TableCell>
            <TableCell className={classes.tablecell}>ドイツ語</TableCell>
            <TableCell className={classes.tablecell}>英語</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.word.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tablecell}>{row.jp}</TableCell>
              <TableCell className={classes.tablecell}>{row.es}</TableCell>
              <TableCell className={classes.tablecell}>{row.it}</TableCell>
              <TableCell className={classes.tablecell}>{row.de}</TableCell>
              <TableCell className={classes.tablecell}>{row.en}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LanguageTable;
