import React, { useContext } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { JTeamDivisionContext } from "./context/JTeamDivisionContext";

const JTeamDivisionMUI = () => {
  const [division, setDivision] = useContext(JTeamDivisionContext);

  const handleChange = (event, val) => {
    setDivision(val);
    // debugger;
  };

  return (
    <ToggleButtonGroup
      value={division}
      exclusive
      size="small"
      onChange={handleChange}
      aria-label="division"
    >
      <ToggleButton value="J1" aria-label="J1">
        J1
      </ToggleButton>
      <ToggleButton value="J2" aria-label="J2">
        J2
      </ToggleButton>
      <ToggleButton value="J3" aria-label="J3">
        J3
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default JTeamDivisionMUI;
