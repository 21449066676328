import React, { createContext, useState } from "react";

const initialState = {
  nation_type: {
    イタリア: ["イタリアオペラ", "イタリアPOP"],
    スペイン: ["スパニッシュPOP"],
    ドイツ: ["ドイツクラシック", "シュラガーソング"],
    イングランド: ["イングランドROCK"],
  },
  nation: "イタリア",
  type: "イタリアPOP",
  table: "songs",
  playlist: [],
  songlist: [{}],
};

export const YoutubeContext = createContext(initialState);

export const YoutubeContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <YoutubeContext.Provider value={[state, setState]}>
      {children}
    </YoutubeContext.Provider>
  );
};
