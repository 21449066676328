import React, { createContext, useState } from "react";

const initialState = {
  id: 0,
  type:'ピックアップ'
};

export const SoccerWordContext = createContext(initialState);

export const SoccerWordContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <SoccerWordContext.Provider value={[state, setState]}>
      {children}
    </SoccerWordContext.Provider>
  );
};
