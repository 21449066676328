import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import axios from "axios";
import { SoccerWordContext } from "./context/SoccerWordContext";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

const SoccerWordCard = () => {
  const classes = useStyles();

  const [state] = useContext(SoccerWordContext);
  const [article, setArticle] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let params = new URLSearchParams();
      params.append("id", state.id);
      params.append("type", state.type);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/getDataMultiWord.php`,
        params
      );
      setArticle(response.data[0]);
    };
    getData();
  }, [state.id,state.type]);

  let createMarkup = function () {
    return { __html: article.content };
  };

  return (
    <Card className={classes.root}>
      <CardContent>
         <div dangerouslySetInnerHTML={createMarkup()}></div>
      </CardContent>
    </Card>
  );
};

export default SoccerWordCard;
