import React, { useState } from "react";
import { Timeline } from "react-twitter-widgets";
import Select from "react-select";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const LeagueOptions = {
  LaLiga: [
    { value: "LaLiga", label: "ラ・リーガ" },
    { value: "realmadrid", label: "レアル・マドリード" },
    { value: "FCBarcelona_es", label: "バルセロナ" },
    { value: "Atleti", label: "アトレティコ・マドリード" },
    { value: "SevillaFC", label: "セビージャ" },
    { value: "VillarrealCF", label: "ビジャレアル" },
    { value: "RealSociedad", label: "レアル・ソシエダ" },
    { value: "GranadaCdeF", label: "グラナダ" },
    { value: "GetafeCF", label: "ヘタフェ" },
    { value: "valenciacf", label: "バレンシア" },
    { value: "CAOsasuna", label: "オサスナ" },
    { value: "athleticclub", label: "アスレティック・ビルバオ" },
    { value: "levanteud", label: "レバンテ" },
    { value: "realvalladolid", label: "バリャドリード" },
    { value: "SDEibar", label: "エイバル" },
    { value: "RealBetis", label: "ベティス" },
    { value: "alaves", label: "アラベス" },
    { value: "RCCelta", label: "セルタ・ビーゴ" },
    { value: "SDHuesca", label: "ウエスカ" },
    { value: "Cadiz_CF", label: "カディス" },
    { value: "elchecf", label: "エルチェ" },
  ],
  SerieA: [
    { value: "SerieA", label: "セリエＡ" },
    { value: "juventusfc", label: "ユヴェントス" },
    { value: "Inter", label: "インテル" },
    { value: "Atalanta_BC", label: "アタランタ" },
    { value: "OfficialSSLazio", label: "ラツィオ" },
    { value: "OfficialASRoma", label: "ローマ" },
    { value: "acmilan", label: "ミラン" },
    { value: "SSCNapoli", label: "ナポリ" },
    { value: "SassuoloUS", label: "サッスオーロ" },
    { value: "HellasVeronaFC", label: "ヴェローナ" },
    { value: "acffiorentina", label: "フィオレンティーナ" },
    { value: "1913parmacalcio", label: "パルマ" },
    { value: "BfcOfficialPage", label: "ボローニャ" },
    { value: "Udinese_1896", label: "ウディネーゼ" },
    { value: "CagliariCalcio", label: "カリアリ" },
    { value: "sampdoria", label: "サンプドリア" },
    { value: "TorinoFC_1906", label: "トリノ" },
    { value: "GenoaCFC", label: "ジェノア" },
    { value: "bncalcio", label: "ベネヴェント" },
    { value: "fccrotoneoff", label: "クロトーネ" },
    { value: "acspezia", label: "スペツィア" },
  ],
  bundesliga_de: [
    { value: "bundesliga_de", label: "ブンデス" },
    { value: "FCBayern", label: "バイエルン・ミュンヘン" },
    { value: "BVB", label: "ドルトムント" },
    { value: "DieRotenBullen", label: "ライプツィヒ" },
    { value: "borussia", label: "ボルシアMG" },
    { value: "bayer04fussball", label: "レバークーゼン" },
    { value: "tsghoffenheim", label: "ホッフェンハイム" },
    { value: "VfL_Wolfsburg", label: "ヴォルフスブルク" },
    { value: "scfreiburg", label: "フライブルク" },
    { value: "Eintracht", label: "フランクフルト" },
    { value: "HerthaBSC", label: "ヘルタ・ベルリン" },
    { value: "fcunion", label: "ウニオン・ベルリン" },
    { value: "s04", label: "シャルケ" },
    { value: "1fsvmainz05", label: "マインツ" },
    { value: "fckoeln", label: "ケルン" },
    { value: "FCAugsburg", label: "アウクスブルク" },
    { value: "arminia", label: "ビーレフェルト" },
    { value: "vfb", label: "シュトゥットガルト" },
    { value: "werderbremen", label: "ブレーメン" },
  ],
  premierleague: [
    { value: "premierleague", label: "プレミア" },
    { value: "LFC", label: "リヴァプール" },
    { value: "ManCity", label: "マンチェスター・シティ" },
    { value: "ManUtd", label: "マンチェスター・ユナイテッド" },
    { value: "ChelseaFC", label: "チェルシー" },
    { value: "LCFC", label: "レスター・シティ" },
    { value: "SpursOfficial", label: "トッテナム・ホットスパー" },
    { value: "Wolves", label: "ウルヴァーハンプトン・ワンダラーズ" },
    { value: "Arsenal", label: "アーセナル" },
    { value: "SheffieldUnited", label: "シェフィールド・ユナイテッド" },
    { value: "BurnleyOfficial", label: "バーンリー" },
    { value: "SouthamptonFC", label: "サウサンプトン" },
    { value: "Everton", label: "エヴァートン" },
    { value: "NUFC", label: "ニューカッスル・ユナイテッド" },
    { value: "CPFC", label: "クリスタル・パレス" },
    { value: "OfficialBHAFC", label: "ブライトン&ホーヴ・アルビオン" },
    { value: "WestHam", label: "ウェストハム・ユナイテッド" },
    { value: "AVFCOfficial", label: "アストン・ヴィラ" },
    { value: "LUFC", label: "リーズ・ユナイテッド" },
    { value: "WBA", label: "ウェスト・ブロムウィッチ・アルビオン" },
    { value: "FulhamFC", label: "フラム" },
  ],
};

const TwitterTimeLine2 = () => {
  const [league, setLeague] = useState("LaLiga");
  const [url, setUrl] = useState("LaLiga");
  const [options, setOptions] = useState(LeagueOptions.LaLiga);

  const handleChange = (event, val) => {
    setLeague(val);
    setOptions(LeagueOptions[val]);
    setUrl(LeagueOptions[val][0].value);
  };

  const handleChangeSelect = (selectedOption) => {
    setUrl(selectedOption.value);
  };

  return (
    <div>
      <ToggleButtonGroup
        value={league}
        exclusive
        size="small"
        onChange={handleChange}
        aria-label="league"
      >
        <ToggleButton value="LaLiga" aria-label="LaLiga">
          ラ・リーガ
        </ToggleButton>
        <ToggleButton value="SerieA" aria-label="SerieA">
          セリエＡ
        </ToggleButton>
        <ToggleButton value="bundesliga_de" aria-label="bundesliga_de">
          ブンデス
        </ToggleButton>
        <ToggleButton value="premierleague" aria-label="premierleague">
          プレミア
        </ToggleButton>
      </ToggleButtonGroup>
      <Select
        // defaultValue={options[0]}
        value={options.filter((option) => option.value === url)}
        // isMulti
        name="colors"
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChangeSelect}
        isSearchable={false}
        // clearValue={}
      />
      <Timeline
        dataSource={{
          sourceType: "profile",
          screenName: url,
        }}
        options={{
          // size: "large",
          lang: "ja",
          chrome: "noheader, nofooter",
          // width: "500",
          height: "400",
        }}
      />
      {/* <a
        class="twitter-timeline"
        href={"https://twitter.com/" + url}
        data-chrome="nofooter noborders"
        data-height="400"
      >
      </a> */}
      
    </div>
  );
};

export default TwitterTimeLine2;
