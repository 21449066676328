import React, { createContext, useState } from "react";

const initialState = {
  type: "イタリアPOP",
  wordlist: [],
  wordcategory: "全て",
  dispcount: 10,
};

export const WordContext = createContext(initialState);

export const WordContextProvider = ({ children }) => {
  const [wordstate, setWordState] = useState(initialState);

  return (
    <WordContext.Provider value={[wordstate, setWordState]}>
      {children}
    </WordContext.Provider>
  );
};
