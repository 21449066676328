import React, { useContext } from "react";
import Select from "react-select";
import { JSeasonContext } from "./context/JSeasonContext";

const getYearArray = (from, to = new Date().getFullYear()) => {
    let years = [];
    [...Array(to - from + 1)]
        .map((_, index) => years.push({ value: to - index, label: to - index }));
    return years;
};
const options = getYearArray(1999);

const JSeasonYear = () => {
  const [state, setState] = useContext(JSeasonContext);

  const handleChange = (selectedOption) => {
    setState((state) => ({ ...state, year: selectedOption.value }));
  };

  return (
    <div>
      <Select
        defaultValue={options[0]}
        name="year"
        options={options}
        className="basic-single"
        classNamePrefix="select"
        onChange={handleChange}
        isSearchable={false}
      />
    </div>
  );
};

export default JSeasonYear;
