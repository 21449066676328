import React from 'react'

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { LanguageContextProvider } from "./context/LanguageContext";
import LanguageTable from "./LanguageTable";
import LanguageComment from "./LanguageComment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    // padding: '0 30px',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

const Language = () => {
    const classes = useStyles();
    return (
      <LanguageContextProvider>
        <div>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Box mb={1}>
                <Typography variant="subtitle2" color="primary">
                  なるべく類似した単語をピックアップ！！
                </Typography>
              </Box>
              <Box mb={3}>
                <Paper className={classes.paper}>
                  <LanguageTable />
                </Paper>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box mb={1}>
                <Typography variant="subtitle2" color="primary">
                  言語とサッカーの特色を関連付けてみる！！
                </Typography>
              </Box>
              <Box mb={3}>
                <Paper className={classes.paper}>
                  <LanguageComment />
                </Paper>
              </Box>
              <Box mb={3}>
                <Paper className={classes.paper}>
                  {/* <PlayerNationSum /> */}
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </div>
      </LanguageContextProvider>
    );
}

export default Language
