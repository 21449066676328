import React, { useContext } from "react";
import Select from "react-select";
import { PlayerNationContext } from "../context/PlayerNationContext";

const options = [
  { value: "ENG1", label: "プレミアリーグ" },
  { value: "GER1", label: "ブンデスリーガ" },
  { value: "ESP1", label: "ラ・リーガ" },
  { value: "ITA1", label: "セリエＡ" },
];

const PlayerNationCategory = () => {
  const [playerstate, setPlayerState] = useContext(PlayerNationContext);

  const handleChange = (selectedOption) => {
    setPlayerState((playerstate) => ({
      ...playerstate,
      league_id: selectedOption.value,
    }));
  };

  return (
    <div>
      <Select
        defaultValue={options[0]}
        name="year"
        options={options}
        className="basic-single"
        classNamePrefix="select"
        onChange={handleChange}
        isSearchable={false}
      />
    </div>
  );
};

export default PlayerNationCategory;
