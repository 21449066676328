import React from "react";
import Grid from "@material-ui/core/Grid";
import { JTeamContextProvider } from "./context/JTeamContext";
import { JTeamDivisionContextProvider } from "./context/JTeamDivisionContext";
import JTeamSelect from "./JTeamSelect";
import JTeamDivision from "./JTeamDivisionMUI";
import JTeamChart from "./JTeamChart";
import { FcComboChart } from "react-icons/fc";

const JTeam = () => {
  return (
    <JTeamContextProvider>
      <JTeamDivisionContextProvider>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FcComboChart size="2em" />
            <ruby>
              チーム<rp>(</rp>
              <rt>1試合平均</rt>
              <rp>)</rp>
            </ruby>
          </Grid>
          <Grid item xs>
            <JTeamSelect />
          </Grid>
          <Grid item xs>
            <JTeamDivision />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs>
            <JTeamChart />
          </Grid>
        </Grid>
        {/* <Container fluid className="mb-4 py-2 border border-success rounded">
          <Row>
            <Col md={4}>
              <Row>
                <Col md={3}>
                  <FcComboChart size="2em" />
                </Col>
                <Col>
                  <ruby>
                    チーム<rp>(</rp>
                    <rt>1試合平均</rt>
                    <rp>)</rp>
                  </ruby>
                </Col>
              </Row>
            </Col>
            <Col>
              <JTeamSelect />
            </Col>
            <Col>
              <JTeamDivision />
            </Col>
          </Row>
          <Row>
            <Col style={{ padding: 0 }}>
              <JTeamChart />
            </Col>
          </Row>
        </Container> */}
      </JTeamDivisionContextProvider>
    </JTeamContextProvider>
  );
};

export default JTeam;
