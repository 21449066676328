import React from "react";
import Grid from "@material-ui/core/Grid";
import { FcComboChart } from "react-icons/fc";

// import PlayerNationCategory from "./PlayerNationCategory";
import PlayerNationSumDispCount from "./PlayerNationSumDispCount";
import PlayerNationSumBar from "./PlayerNationSumBar";
import PlayerNationSumTable from "./PlayerNationSumTable";

const PlayerNationSum = () => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FcComboChart size="2em" />
          国籍別選手数
        </Grid>
        <Grid item xs>
          <PlayerNationSumDispCount />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <PlayerNationSumBar />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <PlayerNationSumTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default PlayerNationSum;
