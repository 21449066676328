import React from "react";
import Grid from "@material-ui/core/Grid";
import EUTeamChart from "./EUTeamChart";
import { FcComboChart } from "react-icons/fc";

const Score = () => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <FcComboChart size="2em" />
          <ruby>
            得点<rp>(</rp>
            <rt>1試合平均</rt>
            <rp>)</rp>
          </ruby>
        </Grid>
        <Grid item xs>
          リーグ
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs>
          <EUTeamChart />
        </Grid>
      </Grid>
    </div>

    // <Container fluid className="mb-4 py-2 border border-success rounded">
    //   <Row>
    //     <Col md={4}>
    //       <Row>
    //         <Col md={3}>
    //           <FcComboChart size="2em" />
    //         </Col>
    //         <Col>
    //           <ruby>
    //             得点<rp>(</rp>
    //             <rt>1試合平均</rt>
    //             <rp>)</rp>
    //           </ruby>
    //         </Col>
    //       </Row>
    //     </Col>
    //     <Col>
    //       リーグ
    //     </Col>
    //   </Row>
    //   <Row>
    //     <Col style={{ padding: 0 }}><EUTeamChart /></Col>
    //   </Row>
    // </Container>
  );
};

export default Score;
