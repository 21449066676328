import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import ResponsiveDrawer from "./components/header/ResponsiveDrawer";
import FooterDiv from "./components/footer/FooterDiv";
import SoccerWord from "./components/soccer/word/SoccerWord";
import Player from "./components/soccer/player/Player";
import JLeague from "./components/soccer/jleague/JLeague";
import Language from "./components/language/Language";
import Music from "./components/music/Music";
import NotFound from "./NotFound";

function App() {
  return (
    <HashRouter>
      <ResponsiveDrawer>
        <Switch>
          <Route exact path="/" component={SoccerWord} />
          <Route path="/stats/playernation" component={Player} />
          <Route path="/stats/shoot" component={JLeague} />
          <Route path="/language" component={Language} />
          <Route path="/music" component={Music} />
          <Route component={NotFound} />
        </Switch>
      </ResponsiveDrawer>
      <FooterDiv />
    </HashRouter>
  );
}

export default App;
