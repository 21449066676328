import React, { createContext, useState } from "react";

const initialState = {
  word: [
    { jp: "(私は)行く", es: "voy", it: "vado", de: "gehe", en: "go" },
    { jp: "(君は)行く", es: "vas", it: "vai", de: "gehst", en: "go" },
    { jp: "(彼は)行く", es: "va", it: "va", de: "geht", en: "goes" },
    { jp: "来る", es: "venir", it: "venire", de: "kommen", en: "come" },
    { jp: "見る", es: "ver", it: "vedere", de: "sehen", en: "see" },
    { jp: "暮す", es: "vivir", it: "vivere", de: "leben", en: "live" },
    { jp: "生活", es: "vida", it: "vita", de: "Leben", en: "life" },
    { jp: "愛する", es: "amar", it: "amare", de: "lieben", en: "love" },
    { jp: "愛", es: "amor", it: "amore", de: "Lieben", en: "love" },
    { jp: "心", es: "corazón", it: "cuore", de: "Herz", en: "heart" },
    { jp: "考える", es: "pensar", it: "pensare", de: "denken", en: "think" },
    { jp: "読む", es: "leer", it: "leggere", de: "lesen", en: "read" },
    { jp: "レッスン", es: "lección", it: "lezione", de: "Stunde", en: "lesson" },
    { jp: "書く", es: "escribir", it: "scrivere", de: "schreiben", en: "write" },
    { jp: "文章", es: "escrito", it: "scritto", de: "Skript", en: "script" },
    { jp: "学習する", es: "estudiar", it: "studiare", de: "studieren", en: "study" },
    { jp: "学習", es: "estudio", it: "studio", de: "Studium", en: "study" },
    { jp: "知る", es: "saber", it: "sapere", de: "wissen", en: "know" },
    { jp: "知識", es: "conocimiento", it: "conoscenza", de: "Wissen", en: "knowledge" },
    { jp: "賢い", es: "sabio", it: "saggio", de: "weise", en: "wise" },
    { jp: "言う", es: "decir", it: "dire", de: "sagen", en: "say" },
    { jp: "言葉", es: "palabra", it: "parola", de: "Wort", en: "word" },
    { jp: "歌う", es: "cantar", it: "cantare", de: "singen", en: "sing" },
    { jp: "声", es: "voz", it: "voce", de: "Stimme", en: "voice" },
    { jp: "口", es: "boca", it: "bocca", de: "Mund", en: "mouth" },
    { jp: "飲む", es: "beber", it: "bere", de: "trinken", en: "drink" },
    { jp: "水", es: "agua", it: "acqua", de: "Wasser", en: "water" },
    { jp: "座る", es: "sentarse", it: "sedere", de: "sitzen", en: "sit" },
    { jp: "踊る", es: "bailar", it: "ballare", de: "tanzen", en: "dance" },
    { jp: "踊り", es: "baile", it: "ballo", de: "Tanz", en: "dance" },
    { jp: "日", es: "día", it: "giorno", de: "Tag", en: "day" },
    { jp: "夜", es: "noche", it: "notte", de: "Nacht", en: "night" },
    { jp: "全ての", es: "total", it: "totale", de: "ganz", en: "total" },
  ],
};

export const LanguageContext = createContext(initialState);

export const LanguageContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <LanguageContext.Provider value={[state, setState]}>
      {children}
    </LanguageContext.Provider>
  );
};
