import React from "react";
import Grid from "@material-ui/core/Grid";
import EUTeamChart from "./EUTeamChart";
import { FcComboChart } from "react-icons/fc";

const EUTeam = () => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <FcComboChart size="2em" />
          <ruby>
            チーム<rp>(</rp>
            <rt>1試合平均</rt>
            <rp>)</rp>
          </ruby>
        </Grid>
        <Grid item xs>
          欧州各リーグトップ(2019-2020)
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <EUTeamChart />
        </Grid>
      </Grid>
    </div>
  );
};

export default EUTeam;
