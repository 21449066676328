import React, { useContext } from "react";
import { WordContext } from "../context/WordContext";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

export default function WordDispCount() {
  const [wordstate, setWordState] = useContext(WordContext);

  const handleChange = (event, value) => {
    setWordState((wordstate) => ({
      ...wordstate,
      dispcount: Number(value),
    }));
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={String(wordstate.dispcount)}
      exclusive
      onChange={handleChange}
      aria-label="text alignment"
    >
      <ToggleButton value="10" aria-label="left aligned">
        Top10
      </ToggleButton>
      <ToggleButton value="20" aria-label="right aligned">
        Top20
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
