import React, { createContext, useState } from "react";

const initialState = {
  time: new Date(),
  id: 0,
};

export const YoutubeIDContext = createContext(initialState);

export const YoutubeIDContextProvider = ({ children }) => {
  const [idstate, setIDState] = useState(initialState);

  return (
    <YoutubeIDContext.Provider value={[idstate, setIDState]}>
      {children}
    </YoutubeIDContext.Provider>
  );
};
