import React, { useEffect, useState, useContext } from "react";
import YouTube from "react-youtube";
import "./YoutubePlayer.css";
import { YoutubeContext } from "../context/YoutubeContext";
import { YoutubeIDContext } from "../context/YoutubeIDContext";

const YoutubePlayer = () => {
  const [state] = useContext(YoutubeContext);
  const [player, setPlayer] = useState({});
  const [idstate, setIDState] = useContext(YoutubeIDContext);

  const opts = {
    width: 500,
    height: 280,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  useEffect(() => {
    if (player !== undefined && player.event !== undefined)
      player.event.target.loadPlaylist(state.playlist);
  }, [player, state.playlist]);

  useEffect(() => {
    if (player !== undefined && player.event !== undefined)
      player.event.target.playVideoAt(idstate.id);
  }, [player, idstate.id]);

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.loadPlaylist(state.playlist);
    // debugger;
    setPlayer({ event: event });
  };

  const _onStateChange = (event) => {
    console.log(event);
    if (event.data === 1 && idstate.id !== event.target.playerInfo.playlistIndex) {
      setIDState({
        time: new Date(),
        id: event.target.playerInfo.playlistIndex,
      });
    }
  }

  return (
    <div className="movie-wrap">
      <YouTube opts={opts} onReady={_onReady} onStateChange={_onStateChange} />
    </div>
  );
};

export default YoutubePlayer;
