import React from "react";
import { SoccerWordContextProvider } from "./context/SoccerWordContext";

import SoccerWordCard from "./SoccerWordCard";
import SoccerWordTable from "./SoccerWordTable";
import TwitterTimeLine2 from "./TwitterTimeLine2";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import { blue } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    // padding: '0 30px',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

const SoccerWord = () => {
  const classes = useStyles();
  return (
    <SoccerWordContextProvider>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="primary">
              欧州主要リーグ＆チームのツイッターを現地語で読むための単語メモ随時更新中！！
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box mb={2}>
              <Paper className={classes.paper}>
                <SoccerWordTable />
              </Paper>
            </Box>
            <Paper className={classes.paper}>
              <SoccerWordCard />
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper className={classes.paper}>
                <TwitterTimeLine2 />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </SoccerWordContextProvider>
  );
};

export default SoccerWord;
