import React, { useEffect, useRef, useState, useContext } from "react";
import Chartjs from "chart.js";
// import axios from "axios";
import { PlayerNationContext } from "../context/PlayerNationContext";

const chartConfig = {
  type: "horizontalBar",
  data: {
    labels: [],
    datasets: [
      {
        label: "ENG1",
        data: [],
        backgroundColor: "rgba(204,153,255, 1)",
        borderColor: "rgba(204,153,255, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(204,153,255,1)",
        hoverBorderColor: "rgba(204,153,255,1)",
      },
      {
        label: "GER1",
        data: [],
        backgroundColor: "rgba(102,153,255, 1)",
        borderColor: "rgba(102,153,255, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(102,153,255,1)",
        hoverBorderColor: "rgba(102,153,255,1)",
      },
      {
        label: "ESP1",
        data: [],
        backgroundColor: "rgba(255,204, 51, 1)",
        borderColor: "rgba(255,204, 51, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,204, 51,1)",
        hoverBorderColor: "rgba(255,204, 51,1)",
      },
      {
        label: "ITA1",
        data: [],
        backgroundColor: "rgba(255,153,153, 1)",
        borderColor: "rgba(255,153,153, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,153,153,1)",
        hoverBorderColor: "rgba(255,153,153,1)",
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
    },
    tooltips: {
      mode: "index",
      intersect: true,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  },
};

const PlayerNationSumBar = () => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [style, setStyle] = useState({
    position: "relative",
    height: "300px",
    width: "100",
  });
  const [playerstate] = useContext(PlayerNationContext);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  useEffect(() => {
    if (chartInstance !== null) {
      chartInstance.data.labels = playerstate.playersumlist.map(
        (row) => row["nation"]
      );
      chartInstance.data.datasets[0].data = playerstate.playersumlist.map(
        (row) => row["ENG1"]
      );
      chartInstance.data.datasets[1].data = playerstate.playersumlist.map(
        (row) => row["GER1"]
      );
      chartInstance.data.datasets[2].data = playerstate.playersumlist.map(
        (row) => row["ESP1"]
      );
      chartInstance.data.datasets[3].data = playerstate.playersumlist.map(
        (row) => row["ITA1"]
      );
      chartInstance.update();
    }
    if (playerstate.dispcountsum === 10) {
      setStyle((style) => ({
        ...style,
        height: "300px",
      }));
    } else if (playerstate.dispcountsum === 20) {
      setStyle((style) => ({
        ...style,
        height: "600px",
      }));
    } else {
      setStyle((style) => ({
        ...style,
        height: "1000px",
      }));
    }
  }, [playerstate.playersumlist, playerstate.dispcountsum, chartInstance]);

  return (
    <div style={style}>
      <canvas ref={chartContainer} />
    </div>
  );
};

export default PlayerNationSumBar;
