import React from "react";
import Grid from "@material-ui/core/Grid";
import { JShootContextProvider } from "./context/JShootContext";
import JShootSelect from "./JShootSelect";
import JShootChart from "./JShootChart";
import { FcComboChart } from "react-icons/fc";

const JShoot = () => {
  return (
    <JShootContextProvider>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <FcComboChart size="2em" />
          <ruby>
            シュー<rp>(</rp>
            <rt>1試合平均</rt>
            <rp>)</rp>ト比較
                </ruby>
        </Grid>
        <Grid item xs>
          <JShootSelect />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <JShootChart />
        </Grid>
      </Grid>
    </JShootContextProvider>
  );
};

export default JShoot;
