import React, { useEffect, useContext, useState } from "react";
import { JTeamContext } from "./context/JTeamContext";
import { JTeamDivisionContext } from "./context/JTeamDivisionContext";
import Select from "react-select";
import axios from "axios";

// const options = [];

const JTeamSelect = () => {
  const [state, setState] = useContext(JTeamContext);
  const [division, setDivision] = useContext(JTeamDivisionContext);
  const [teams, setTeams] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let params = new URLSearchParams();
      params.append("type", "チーム名");
      params.append("type2", "");
      params.append("year", "");
      params.append("division", "");
      params.append("team", "");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/getData.php`,
        params
      );
      setTeams(response.data);
      // let results = response.data.filter((row) => row.Division === division);
      // results.map((row) =>
      //   options.push({ value: row["team_id"], label: row["ShortName"] })
      // );
    };
    getData();
  }, []);

  useEffect(() => {
    const opts = [];
    if (teams !== null) {
      let results = teams.filter((row) => row.Division === division);
      // debugger;
      results.map((row) =>
        opts.push({ value: row["team_id"], label: row["ShortName"] })
      );
      setOptions(opts);
    }
  }, [division, teams]);

  const handleChange = (selectedOption) => {
    setState((state) => ({ ...state, team: selectedOption.value }));
  };

  return (
    <div>
      <Select
        defaultValue={options[0]}
        // isMulti
        name="colors"
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChange}
        isSearchable={false}
      />
    </div>
  );
};

export default JTeamSelect;
