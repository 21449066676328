import React, { createContext, useState } from "react";

const initialState = {
  league_id: "ENG1",
  playerlist: [],
  playersumlist: [],
  playerfratiolist: [],
  dispcount: 10,
  dispcountsum: 10,
  leaguelist: [
    { name: "プレミア", code: "ENG1" },
    { name: "ブンデス", code: "GER1" },
    { name: "ラ・リーガ", code: "ESP1" },
    { name: "セリエＡ", code: "ITA1" },
  ],
  nationlist: [
    { name: "アフガニスタン", code: "AFG" },
    { name: "アンギラ", code: "AIA" },
    { name: "アルバニア", code: "ALB" },
    { name: "アルジェリア", code: "ALG" },
    { name: "アンドラ", code: "AND" },
    { name: "アンゴラ", code: "ANG" },
    { name: "オランダ領アンティル", code: "ANT" },
    { name: "アルゼンチン", code: "ARG" },
    { name: "アルメニア", code: "ARM" },
    { name: "アルバ", code: "ARU" },
    { name: "アメリカ領サモア", code: "ASA" },
    { name: "アンティグア・バーブーダ", code: "ATG" },
    { name: "オーストラリア", code: "AUS" },
    { name: "オーストリア", code: "AUT" },
    { name: "アゼルバイジャン", code: "AZE" },
    { name: "バハマ", code: "BAH" },
    { name: "バングラデシュ", code: "BAN" },
    { name: "ブルンジ", code: "BDI" },
    { name: "ベルギー", code: "BEL" },
    { name: "ベナン", code: "BEN" },
    { name: "バミューダ諸島", code: "BER" },
    { name: "ブルキナファソ", code: "BFA" },
    { name: "バーレーン", code: "BHR" },
    { name: "ブータン", code: "BHU" },
    { name: "ボスニア・ヘルツェゴビナ", code: "BIH" },
    { name: "ベラルーシ", code: "BLR" },
    { name: "ベリーズ", code: "BLZ" },
    { name: "ボリビア", code: "BOL" },
    { name: "ボツワナ", code: "BOT" },
    { name: "ブラジル", code: "BRA" },
    { name: "バルバドス", code: "BRB" },
    { name: "ブルネイ", code: "BRU" },
    { name: "ブルガリア", code: "BUL" },
    { name: "カンボジア", code: "CAM" },
    { name: "カナダ", code: "CAN" },
    { name: "ケイマン諸島", code: "CAY" },
    { name: "コンゴ共和国", code: "CGO" },
    { name: "チャド", code: "CHA" },
    { name: "チリ", code: "CHI" },
    { name: "中国", code: "CHN" },
    { name: "コートジボワール", code: "CIV" },
    { name: "カメルーン", code: "CMR" },
    { name: "コンゴ民主共和国", code: "COD" },
    { name: "クック諸島", code: "COK" },
    { name: "コロンビア", code: "COL" },
    { name: "コモロ", code: "COM" },
    { name: "カーボベルデ", code: "CPV" },
    { name: "コスタリカ", code: "CRC" },
    { name: "クロアチア", code: "CRO" },
    { name: "中央アフリカ", code: "CTA" },
    { name: "キューバ", code: "CUB" },
    { name: "キュラソー", code: "CUW" },
    { name: "キプロス", code: "CYP" },
    { name: "チェコ", code: "CZE" },
    { name: "デンマーク", code: "DEN" },
    { name: "ジブチ", code: "DJI" },
    { name: "ドミニカ国", code: "DMA" },
    { name: "ドミニカ共和国", code: "DOM" },
    { name: "エクアドル", code: "ECU" },
    { name: "エジプト", code: "EGY" },
    { name: "イングランド", code: "ENG" },
    { name: "赤道ギニア", code: "EQG" },
    { name: "エリトリア", code: "ERI" },
    { name: "スペイン", code: "ESP" },
    { name: "エストニア", code: "EST" },
    { name: "エチオピア", code: "ETH" },
    { name: "フィジー", code: "FIJ" },
    { name: "フィンランド", code: "FIN" },
    { name: "フランス", code: "FRA" },
    { name: "フェロー諸島", code: "FRO" },
    { name: "ガボン", code: "GAB" },
    { name: "ガンビア", code: "GAM" },
    { name: "ジョージア", code: "GEO" },
    { name: "ドイツ", code: "GER" },
    { name: "ガーナ", code: "GHA" },
    { name: "ジブラルタル", code: "GIB" },
    { name: "ギニアビサウ", code: "GNB" },
    { name: "ギリシャ", code: "GRE" },
    { name: "グレナダ", code: "GRN" },
    { name: "グアテマラ", code: "GUA" },
    { name: "ギニア", code: "GUI" },
    { name: "グアム", code: "GUM" },
    { name: "ガイアナ", code: "GUY" },
    { name: "ハイチ", code: "HAI" },
    { name: "香港", code: "HKG" },
    { name: "ホンジュラス", code: "HON" },
    { name: "ハンガリー", code: "HUN" },
    { name: "インドネシア", code: "IDN" },
    { name: "インド", code: "IND" },
    { name: "アイルランド", code: "IRL" },
    { name: "イラン", code: "IRN" },
    { name: "イラク", code: "IRQ" },
    { name: "アイスランド", code: "ISL" },
    { name: "イスラエル", code: "ISR" },
    { name: "イタリア", code: "ITA" },
    { name: "ジャマイカ", code: "JAM" },
    { name: "ヨルダン", code: "JOR" },
    { name: "日本", code: "JPN" },
    { name: "カザフスタン", code: "KAZ" },
    { name: "ケニア", code: "KEN" },
    { name: "キルギス", code: "KGZ" },
    { name: "韓国", code: "KOR" },
    { name: "サウジアラビア", code: "KSA" },
    { name: "クウェート", code: "KUW" },
    { name: "コソボ", code: "KVX" },
    { name: "ラオス", code: "LAO" },
    { name: "リベリア", code: "LBR" },
    { name: "リビア", code: "LBY" },
    { name: "セントルシア", code: "LCA" },
    { name: "レソト", code: "LES" },
    { name: "レバノン", code: "LIB" },
    { name: "リヒテンシュタイン", code: "LIE" },
    { name: "リトアニア", code: "LTU" },
    { name: "ルクセンブルク", code: "LUX" },
    { name: "ラトビア", code: "LVA" },
    { name: "マカオ", code: "MAC" },
    { name: "マダガスカル", code: "MAD" },
    { name: "モロッコ", code: "MAR" },
    { name: "マレーシア", code: "MAS" },
    { name: "モルドバ", code: "MDA" },
    { name: "モルディブ", code: "MDV" },
    { name: "メキシコ", code: "MEX" },
    { name: "モンゴル", code: "MGL" },
    { name: "北マケドニア", code: "MKD" },
    { name: "マリ", code: "MLI" },
    { name: "マルタ", code: "MLT" },
    { name: "モンテネグロ", code: "MNE" },
    { name: "モザンビーク", code: "MOZ" },
    { name: "モーリシャス", code: "MRI" },
    { name: "モントセラト", code: "MSR" },
    { name: "モーリタニア", code: "MTN" },
    { name: "マラウイ", code: "MWI" },
    { name: "ミャンマー", code: "MYA" },
    { name: "ナミビア", code: "NAM" },
    { name: "ニカラグア", code: "NCA" },
    { name: "ニューカレドニア", code: "NCL" },
    { name: "オランダ", code: "NED" },
    { name: "ネパール", code: "NEP" },
    { name: "ナイジェリア", code: "NGA" },
    { name: "ニジェール", code: "NIG" },
    { name: "北アイルランド", code: "NIR" },
    { name: "ノルウェー", code: "NOR" },
    { name: "ニュージーランド", code: "NZL" },
    { name: "オマーン", code: "OMA" },
    { name: "パキスタン", code: "PAK" },
    { name: "パナマ", code: "PAN" },
    { name: "パラグアイ", code: "PAR" },
    { name: "ペルー", code: "PER" },
    { name: "フィリピン", code: "PHI" },
    { name: "パレスチナ", code: "PLE" },
    { name: "パプアニューギニア", code: "PNG" },
    { name: "ポーランド", code: "POL" },
    { name: "ポルトガル", code: "POR" },
    { name: "朝鮮民主主義人民共和国", code: "PRK" },
    { name: "プエルトリコ", code: "PUR" },
    { name: "カタール", code: "QAT" },
    { name: "ルーマニア", code: "ROU" },
    { name: "南アフリカ共和国", code: "RSA" },
    { name: "ロシア", code: "RUS" },
    { name: "ルワンダ", code: "RWA" },
    { name: "サモア", code: "SAM" },
    { name: "スコットランド", code: "SCO" },
    { name: "セネガル", code: "SEN" },
    { name: "セーシェル", code: "SEY" },
    { name: "シンガポール", code: "SIN" },
    { name: "セントクリストファー・ネイビス", code: "SKN" },
    { name: "シエラレオネ", code: "SLE" },
    { name: "エルサルバドル", code: "SLV" },
    { name: "サンマリノ", code: "SMR" },
    { name: "ソロモン諸島", code: "SOL" },
    { name: "ソマリア", code: "SOM" },
    { name: "セルビア", code: "SRB" },
    { name: "スリランカ", code: "SRI" },
    { name: "南スーダン", code: "SSD" },
    { name: "サントメ・プリンシペ", code: "STP" },
    { name: "スーダン", code: "SUD" },
    { name: "スイス", code: "SUI" },
    { name: "スリナム", code: "SUR" },
    { name: "スロバキア", code: "SVK" },
    { name: "スロベニア", code: "SVN" },
    { name: "スウェーデン", code: "SWE" },
    { name: "エスワティニ", code: "SWZ" },
    { name: "シリア", code: "SYR" },
    { name: "タヒチ", code: "TAH" },
    { name: "タンザニア", code: "TAN" },
    { name: "タークス・カイコス諸島", code: "TCA" },
    { name: "トンガ", code: "TGA" },
    { name: "タイ", code: "THA" },
    { name: "タジキスタン", code: "TJK" },
    { name: "トルクメニスタン", code: "TKM" },
    { name: "東ティモール", code: "TLS" },
    { name: "トーゴ", code: "TOG" },
    { name: "チャイニーズタイペイ", code: "TPE" },
    { name: "トリニダード・トバゴ", code: "TRI" },
    { name: "チュニジア", code: "TUN" },
    { name: "トルコ", code: "TUR" },
    { name: "アラブ首長国連邦", code: "UAE" },
    { name: "ウガンダ", code: "UGA" },
    { name: "ウクライナ", code: "UKR" },
    { name: "ウルグアイ", code: "URU" },
    { name: "アメリカ合衆国", code: "USA" },
    { name: "ウズベキスタン", code: "UZB" },
    { name: "バヌアツ", code: "VAN" },
    { name: "ベネズエラ", code: "VEN" },
    { name: "イギリス領ヴァージン諸島", code: "VGB" },
    { name: "ベトナム", code: "VIE" },
    { name: "セントビンセント・グレナディーン", code: "VIN" },
    { name: "アメリカ領ヴァージン諸島", code: "VIR" },
    { name: "ウェールズ", code: "WAL" },
    { name: "イエメン", code: "YEM" },
    { name: "ザンビア", code: "ZAM" },
    { name: "ジンバブエ", code: "ZIM" },
  ],
};

export const PlayerNationContext = createContext(initialState);

export const PlayerNationContextProvider = ({ children }) => {
  const [playerstate, setPlayerState] = useState(initialState);

  return (
    <PlayerNationContext.Provider value={[playerstate, setPlayerState]}>
      {children}
    </PlayerNationContext.Provider>
  );
};
