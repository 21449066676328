import React from "react";
import Grid from "@material-ui/core/Grid";
import { FcComboChart } from "react-icons/fc";

import PlayerNationFRatioBar from "./PlayerNationFRatioBar";
import PlayerNationFRatioTable from "./PlayerNationFRatioTable";

const PlayerNationFRatio = () => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs>
          <FcComboChart size="2em" />
          リーグ別自外国選手割合
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <PlayerNationFRatioBar />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <PlayerNationFRatioTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default PlayerNationFRatio;
