import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const FooterDiv = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container style={{ backgroundColor: "#cfe8fc", height: "50px" }}>
        <Grid container direction="row" justify="center" alignItems="center" style={{height: "50px" }}>
          © MediaSoftNetwork
          {/* <Typography align="center" component="div" variant="subtitle2">
           
          </Typography> */}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default FooterDiv;
