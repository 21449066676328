import React, { useEffect, useContext } from "react";
// import { Table, ListGroup } from "react-bootstrap";
import axios from "axios";
import { YoutubeContext } from "../context/YoutubeContext";
import { YoutubeIDContext } from "../context/YoutubeIDContext";
import LibraryMusicSharpIcon from "@material-ui/icons/LibraryMusicSharp";
import MicIcon from "@material-ui/icons/Mic";
// import TitleTranslation from "./TitleTranslation";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles({
  table: {
    minWidth: 100,
  },
});

const YoutubeTable = () => {
  const [state, setState] = useContext(YoutubeContext);
  const [idstate, setIDState] = useContext(YoutubeIDContext);
  const classes = useStyles();

  useEffect(() => {
    const getData = async () => {
      let params = new URLSearchParams();
      params.append("type", state.type);
      params.append("table", state.table);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/getDataMusic.php`,
        params
      );
      let vids = response.data.map(function (row) {
        return [row["vid"]];
      });
      setState((state) => ({
        ...state,
        playlist: vids,
        songlist: response.data,
      }));
    };
    getData();
  }, [state.type, state.table, setState]);

  const tableCellClick = (event) => {
    setIDState({
      time: new Date(),
      id: event.currentTarget.id,
    });
  };

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableBody>
          {state.songlist.map((song, index) => (
            <TableRow key={index} onClick={tableCellClick} id={index}>
              <TableCell  padding="none">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`https://i.ytimg.com/vi/${song.vid}/mqdefault.jpg`}
                    alt=""
                    style={{ width: 168 }}
                  ></img>
                </div>
              </TableCell>
              <TableCell padding="none">
                <List
                  aria-label="main mailbox folders"
                  dense="true"
                  disablePadding="true"
                >
                  <ListItem button alignItems="flex-start">
                    <ListItemIcon>
                      <LibraryMusicSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary={song.title} />
                  </ListItem>
                  <ListItem button alignItems="flex-start">
                    <ListItemIcon>
                      <MicIcon />
                    </ListItemIcon>
                    <ListItemText primary={song.singer} />
                  </ListItem>
                </List>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    // <Table size="sm" style={{ cursor: "pointer" }} responsive>
    //   <tbody>
    //     {state.songlist.map((song, index) => (
    //       <tr key={index} onClick={tableCellClick} id={index}>
    //         <td>
    //           <img
    //             src={`https://i.ytimg.com/vi/${song.vid}/mqdefault.jpg`}
    //             alt=""
    //             style={{ width: 168 }}
    //           ></img>
    //         </td>
    //         <td>
    //           <ListGroup variant="flush" className="my-0 py-0">
    //             <ListGroup.Item className="my-0 pl-0 py-0 d-flex border-0">
    //               <LibraryMusicSharpIcon color="primary" className="mr-3" />
    //               <div class="d-block text-truncate">{song.title}</div>
    //             </ListGroup.Item>
    //             <ListGroup.Item className="my-0 pl-0 py-0 d-flex border-0">
    //               <MicIcon color="primary" className="mr-3" />
    //               <div class="d-block text-truncate" style={{ width: 200 }}>
    //                 {song.singer}
    //               </div>
    //             </ListGroup.Item>
    //             <ListGroup.Item className="my-0 pl-0 py-0 d-flex border-0">
    //               <TitleTranslation
    //                 key={song.vid}
    //                 song={song}
    //                 type={state.type}
    //                 rowindex="1"
    //               />
    //             </ListGroup.Item>
    //             <ListGroup.Item className="my-0 pl-0 py-0 d-flex border-0">
    //               <TitleTranslation
    //                 key={song.vid}
    //                 song={song}
    //                 type={state.type}
    //                 rowindex="2"
    //               />
    //             </ListGroup.Item>
    //           </ListGroup>
    //         </td>
    //       </tr>
    //     ))}
    //   </tbody>
    // </Table>
  );
};

export default YoutubeTable;
