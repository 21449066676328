import React, { useEffect, useRef, useState, useContext } from "react";
import Chartjs from "chart.js";
import axios from "axios";
import { JShootContext } from "./context/JShootContext";

const chartConfig = {
  type: "line",
  data: {
    labels: [],
    datasets: [
      {
        label: "チーム１",
        data: [],
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(153, 102, 255,0.4)",
        hoverBorderColor: "rgba(153, 102, 255,1)",
        fill: true,
      },
      {
        label: "チーム２",
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75, 192, 192,0.4)",
        hoverBorderColor: "rgba(75, 192, 192,1)",
        fill: true,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "top",
    },
    tooltips: {
      mode: "index",
      intersect: true,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

const JShootChart = () => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [state, setState] = useContext(JShootContext);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  useEffect(() => {
    const getData = async () => {
      let params = new URLSearchParams();
      params.append("team1", state[0].value);
      params.append("team2", state[1].value);
      // debugger;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/getDataShoot.php`,
        params
      );
      if (chartInstance !== null) {
        chartInstance.data.labels = response.data.map((row) => row["SeasonYear"]);
        chartInstance.data.datasets[0].data = response.data.map((row) => row["Team1"] == 0 ? "NaN" : row["Team1"]);
        chartInstance.data.datasets[1].data = response.data.map((row) => row["Team2"] == 0 ? "NaN" : row["Team2"]);
        chartInstance.data.datasets[0].label = state[0].label;
        chartInstance.data.datasets[1].label = state[1].label;
        chartInstance.update();
      }
    };
    getData();
  }, [state, chartInstance]);

  return (
    <div style={{ position: "relative", height: "300px", width: "100%" }}>
      <canvas ref={chartContainer} />
    </div>
  );
};

export default JShootChart;
