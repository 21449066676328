import React, { useContext, useEffect, useState } from "react";
import { YoutubeContext } from "../context/YoutubeContext";
import { YoutubeIDContext } from "../context/YoutubeIDContext";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
}));

const LyricsAccordion = () => {
  const [state] = useContext(YoutubeContext);
  const [idstate] = useContext(YoutubeIDContext);
  const [url, setUrl] = useState("");
  const [embed, setEmbed] = useState("");

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    console.log(state.songlist);
    console.log(idstate.id);
    setUrl(state.songlist[idstate.id].translate_url);
    setEmbed(state.songlist[idstate.id].translate_url2);
  }, [state.songlist, idstate.id]);

  let createMarkup = function () {
    return { __html: embed };
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>歌詞</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <span dangerouslySetInnerHTML={createMarkup()}></span>{" "}
          </Typography>
        </AccordionDetails>
        <Divider />
        <AccordionActions disableSpacing={false}> 
          歌詞翻訳サイト
          <Button size="small" color="primary">
            <a href={url} rel="noreferrer noopener" target="_blank">
              LyricsTranslate.com
            </a>
          </Button>
        </AccordionActions>
      </Accordion>
    </div>
  );
};

export default LyricsAccordion;
