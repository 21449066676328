import React from "react";

import MusicTBtn from "./MusicTBtn";
// import MusicNav from "./MusicNav";
import YoutubePlayer from "./youtube/YoutubePlayer";
import YoutubeTable from "./youtube/YoutubeTable";
import LyricsAccordion from "./youtube/LyricsAccordion";
import Word from "./word/Word";

import { WordContextProvider } from "./context/WordContext";
import { YoutubeContextProvider } from "./context/YoutubeContext";
import { YoutubeIDContextProvider } from "./context/YoutubeIDContext";



import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    // padding: '0 30px',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

const Music = () => {
  const classes = useStyles();
  
  return (
    <YoutubeContextProvider>
      <YoutubeIDContextProvider>
        <WordContextProvider>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item md={7} xs={12}>
                <Box mb={2}>
                  <Paper className={classes.paper}>
                    <MusicTBtn />
                    {/* <MusicNav /> */}
                  </Paper>
                </Box>

                <Box mb={2}>
                  <Paper className={classes.paper}>
                    <YoutubePlayer />
                    <LyricsAccordion />
                  </Paper>
                </Box>
                <Box mb={2}>
                  <Paper className={classes.paper}>
                    <YoutubeTable />
                  </Paper>
                </Box>
              </Grid>
              <Grid item md={5} xs={12}>
                <Box my={1}>
                  <Paper className={classes.paper}>
                    <Word />
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </div>
        </WordContextProvider>
      </YoutubeIDContextProvider>
    </YoutubeContextProvider>
  );
};

export default Music;
