import React, { useContext } from "react";
import Select from "react-select";
import { WordContext } from "../context/WordContext";

const options = [
  { value: "全て", label: "全て" },
    { value: "名詞", label: "名詞" },
    { value: "動詞", label: "動詞" },
    { value: "副詞", label: "副詞" },
    { value: "形容詞", label: "形容詞" },
    { value: "副詞/形容詞", label: "副詞/形容詞" },
];

const WordCategory = () => {
  const [wordstate, setWordState] = useContext(WordContext);

  const handleChange = (selectedOption) => {
    setWordState((wordstate) => ({ ...wordstate, wordcategory: selectedOption.value }));
  };

  return (
    <div>
      <Select
        defaultValue={options[0]}
        name="year"
        options={options}
        className="basic-single"
        classNamePrefix="select"
        onChange={handleChange}
        isSearchable={false}
      />
    </div>
  );
};

export default WordCategory;
