import React, { createContext, useState } from "react";

const initialState = {
  type: 'シュート反則',
  type2: 'シーズン',
  year: new Date().getFullYear(),
  division: 'J1',
  team: '',
};

export const JSeasonContext = createContext(initialState);

export const JSeasonContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <JSeasonContext.Provider value={[state, setState]}>
      {children}
    </JSeasonContext.Provider>
  );
};
