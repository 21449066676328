import React from "react";
import Grid from "@material-ui/core/Grid";
import WordCategory from "./WordCategory";
import WordDispCount from "./WordDispCount";
import WordBar from "./WordBar";
import WordTable from "./WordTable";
import { FcComboChart } from "react-icons/fc";

const Word = () => {
  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <FcComboChart size="2em" />
          頻出単語
        </Grid>
        <Grid item xs>
          <WordCategory />
        </Grid>
        <Grid item xs>
          <WordDispCount />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs>
          <WordBar />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs>
          <WordTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default Word;
