import React, { useContext } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { JSeasonContext } from "./context/JSeasonContext";

const JSeasonDivisionMUI = () => {
  const [state, setState] = useContext(JSeasonContext);

  const handleChange = (event, val) => {
    setState((state) => ({ ...state, division: val }));
  };

  return (
    <ToggleButtonGroup
      value={state.division}
      exclusive
      size="small"
      onChange={handleChange}
      aria-label="division"
    >
      <ToggleButton value="J1" aria-label="J1">
        J1
      </ToggleButton>
      <ToggleButton value="J2" aria-label="J2">
        J2
      </ToggleButton>
      <ToggleButton value="J3" aria-label="J3">
        J3
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default JSeasonDivisionMUI;
