import React from "react";
import Grid from "@material-ui/core/Grid";
import { FcComboChart } from "react-icons/fc";
// import { PlayerNationContextProvider } from "../context/PlayerNationContext";
import PlayerNationCategory from "./PlayerNationCategory";
import PlayerNationDispCount from "./PlayerNationDispCount";
import PlayerNationBar from "./PlayerNationBar";
import PlayerNationTable from "./PlayerNationTable";

const PlayerNation = () => {
  return (
    // <PlayerNationContextProvider>
    <div>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <FcComboChart size="2em" />
          国籍別選手数
        </Grid>
        <Grid item xs>
          <PlayerNationCategory />
        </Grid>
        <Grid item xs>
          <PlayerNationDispCount />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <PlayerNationBar />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <PlayerNationTable />
        </Grid>
      </Grid>
    </div>
    // </PlayerNationContextProvider>
  );
};

export default PlayerNation;
