import React, { createContext, useState } from "react";

const initialState = {
  type: 'シュート反則',
  type2: 'チーム',
  year: new Date().getFullYear(),
  division: '',
  team: '',
};

export const JTeamContext = createContext(initialState);

export const JTeamContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <JTeamContext.Provider value={[state, setState]}>
      {children}
    </JTeamContext.Provider>
  );
};
