import React from "react";
import Grid from "@material-ui/core/Grid";
import { JSeasonContextProvider } from "./context/JSeasonContext";
import JSeasonChart from "./JSeasonChart";
import JSeasonYear from "./JSeasonYear";
import JSeasonDivision from "./JSeasonDivisionMUI";
import { FcComboChart } from "react-icons/fc";

const JSeason = () => {
  return (
    <JSeasonContextProvider>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <FcComboChart size="2em" />
          <ruby>
            シーズ <rp>(</rp>
            <rt>1試合平均</rt>
            <rp>)</rp>ン
          </ruby>
        </Grid>
        <Grid item xs>
          <JSeasonYear />
        </Grid>
        <Grid item xs>
          <JSeasonDivision />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <JSeasonChart />
        </Grid>
      </Grid>
    </JSeasonContextProvider>
  );
};

export default JSeason;
