import React, { useEffect, useContext } from "react";
import axios from "axios";
import { PlayerNationContext } from "../context/PlayerNationContext";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  table: {
    minWidth: 100,
    maxWidth: "100%",
  },
  tablecell: {
    padding: "5px 15px",
  },
});

const PlayerNationTable = () => {
  const [playerstate, setPlayerState] = useContext(PlayerNationContext);
  const classes = useStyles();

  useEffect(() => {
    const getData = async () => {
      let params = new URLSearchParams();
      params.append("league_id", playerstate.league_id);
      params.append("dispcount", playerstate.dispcount);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/getDataPlayerNation.php`,
        params
      );
      // 国名編集
      let res = response.data.map((row) => {
        const target = playerstate.nationlist.find((nation) => {
          // debugger;
          return nation.code === row["nation"];
        });
        //   debugger;
        if (target !== null && target !== undefined) {
          row["nation"] = target.name;
        }
        return row;
      });
      setPlayerState((playerstate) => ({
        ...playerstate,
        playerlist: res,
      }));
    };
    getData();
  }, [
    playerstate.league_id,
    playerstate.dispcount,
    playerstate.nationlist,
    setPlayerState,
  ]);

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tablecell}>国籍</TableCell>
            <TableCell className={classes.tablecell}>人数</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {playerstate.playerlist.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tablecell}>{row.nation}</TableCell>
              <TableCell className={classes.tablecell}>{row.cnt}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlayerNationTable;
