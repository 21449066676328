import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { PlayerNationContextProvider } from "./context/PlayerNationContext";
import PlayerNation from "./nation/PlayerNation";
import PlayerNationSum from "./sum/PlayerNationSum";
import PlayerNationFRatio from "./fratio/PlayerNationFRatio";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    // padding: '0 30px',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

const Player = () => {
  const classes = useStyles();
  return (
    <PlayerNationContextProvider>
      <div>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box mb={3}>
              <Paper className={classes.paper}>
                <PlayerNation />
              </Paper>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box mb={3}>
              <Paper className={classes.paper}>
                <PlayerNationFRatio />
              </Paper>
            </Box>
            <Box mb={3}>
              <Paper className={classes.paper}>
                <PlayerNationSum />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </div>
    </PlayerNationContextProvider>
  );
};

export default Player;
