import React, { useEffect, useRef, useState, useContext } from "react";
import Chartjs from "chart.js";
import axios from "axios";
import { JTeamContext } from "./context/JTeamContext";

const chartConfig = {
  type: "line",
  data: {
    labels: [],
    datasets: [
      {
        label: "シュート",
        data: [],
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(54, 162, 235,0.4)",
        hoverBorderColor: "rgba(54, 162, 235,1)",
      },
      {
        label: "反則",
        data: [],
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255, 159, 64,0.4)",
        hoverBorderColor: "rgba(255, 159, 64,1)",
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "top",
    },
    tooltips: {
      mode: "index",
      intersect: true,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

const JTeamChart = () => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [state, setState] = useContext(JTeamContext);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  useEffect(() => {
    const getData = async () => {
      let params = new URLSearchParams();
      params.append("type", state.type);
      params.append("type2", state.type2);
      params.append("year", state.year);
      params.append("division", state.division);
      params.append("team", state.team);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/getData.php`,
        params
      );
      if (chartInstance !== null) {
        chartInstance.data.labels = response.data.map(
          (row) => row["SeasonYear"]
        );
        chartInstance.data.datasets[0].data = response.data.map((row) =>
          row["ShootFor"] == 0 ? "NaN" : row["ShootFor"]
        );
        chartInstance.data.datasets[1].data = response.data.map((row) =>
          row["Foul"] == 0 ? "NaN" : row["Foul"]
        );
        chartInstance.update();
        // return num == 0 ? "NaN" : num;
      }
    };
    getData();
  }, [state, chartInstance]);

  return (
    <div style={{ position: "relative", height: "300px", width: "100" }}>
      <canvas ref={chartContainer} />
    </div>
  );
};

export default JTeamChart;
