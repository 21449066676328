import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    // padding: '0 30px',
    textAlign: "left",
    color: theme.palette.text.inherit,
  },
  table: {
    // minWidth: 650,
    marginBottom: "1em",
  },
  tablecell: {
    padding: '5px 0',
  },
}));

function createData(jp, es, it, de, en) {
  return { jp, es, it, de, en };
}

const rows = [
  createData("サッカー", "fútbol(男)", "calcio(男)", "Fußball(男)", "football"),
  createData("足", "pie(女)", "piede(男)", "Fuß(男)", "foot"),
  createData("ボール", "balón(男)", "pallone(男)", "Ball(男)", "ball"),
  createData("ゴール", "gol(男)", "gol(男)", "Tor(中)", "goal"),
];

const LanguageComment = () => {
  const classes = useStyles();
  return (
    <div>
      <Paper className={classes.paper}>
        <Typography variant="subtitle1" gutterBottom>
          ※名詞の性別
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          ・スペイン語：男性＋女性
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          ・イタリア語：男性＋女性
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          ・ドイツ語：男性＋女性＋中性
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          ・英語：区分がなくなった
        </Typography>
        <Typography variant="body2" gutterBottom>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.tablecell}>意味</TableCell>
                  <TableCell align="left" className={classes.tablecell}>スペイン語</TableCell>
                  <TableCell align="left" className={classes.tablecell}>イタリア語</TableCell>
                  <TableCell align="left" className={classes.tablecell}>ドイツ語</TableCell>
                  <TableCell align="left" className={classes.tablecell}>英語</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.jp}>
                    <TableCell align="left" className={classes.tablecell}>{row.jp}</TableCell>
                    <TableCell align="left" className={classes.tablecell}>{row.es}</TableCell>
                    <TableCell align="left" className={classes.tablecell}>{row.it}</TableCell>
                    <TableCell align="left" className={classes.tablecell}>{row.de}</TableCell>
                    <TableCell align="left" className={classes.tablecell}>{row.en}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
        <Typography variant="body2" gutterBottom>
          スペイン語とイタリア語は男女と関係のない名詞が日々出現しているなかで覚えるのが大変だと思いますが、伝統を守る知恵があるのだろうか？
        </Typography>
        <Typography variant="body2" gutterBottom>
          ドイツ語は男女区分の伝統を守りつつも合理性を発揮して中性で対応している？
        </Typography>
        <Typography variant="body2" gutterBottom>
          英語は区分がなくなって、名詞が爆誕する現代に合った言語になった？
        </Typography>
        <Typography variant="body2" gutterBottom>
          ここから、サッカーに引き合わせて考えてみると、
        </Typography>
        <Typography variant="body2" gutterBottom>
          守りに強いスペインとイタリア、
        </Typography>
        <Typography variant="body2" gutterBottom>
          守りも維持しながら合理性を取り入れるドイツ、
        </Typography>
        <Typography variant="body2" gutterBottom>
          シンプルにわかりやすいイングランド、
        </Typography>
        <Typography variant="body2" gutterBottom>
          という感じになってそれぞれのサッカーのスタイルとマッチしている？？
        </Typography>
      </Paper>
    </div>
  );
};

export default LanguageComment;
