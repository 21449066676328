import React, { useEffect, useRef, useState } from "react";
import Chartjs from "chart.js";

const chartConfig = {
  type: "bar",
  data: {
    labels: ["マンC", "バイエルン", "アタランタ", "Rマドリード"],
    datasets: [
      {
        label: "シュート",
        data: [19.6, 18.2, 14.8, 14.5],
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(54, 162, 235,0.4)",
        hoverBorderColor: "rgba(54, 162, 235,1)",
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "top",
    },
    tooltips: {
      mode: "index",
      intersect: true,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

const EUTeamChart = () => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  return (
    <div style={{ position: "relative", height: "300px", width: "100%" }}>
      <canvas ref={chartContainer} />
    </div>
  );
};

export default EUTeamChart;
