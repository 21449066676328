import React, { createContext, useState } from "react";

const initialState = [
  {
    value: "",
    label: "",
  },
  {
    value: "",
    label: "",
  },
];

export const JShootContext = createContext(initialState);

export const JShootContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <JShootContext.Provider value={[state, setState]}>
      {children}
    </JShootContext.Provider>
  );
};
