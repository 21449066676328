import React, { useEffect, useContext } from "react";
import axios from "axios";
import { WordContext } from "../context/WordContext";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  table: {
    // minWidth: 100,
  },
});

const WordTable = () => {
  const [wordstate, setWordState] = useContext(WordContext);
  const classes = useStyles();

  useEffect(() => {
    const getData = async () => {
      let params = new URLSearchParams();
      params.append("type", wordstate.type);
      params.append("wordcategory", wordstate.wordcategory);
      params.append("dispcount", wordstate.dispcount);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/getDataMusicWord.php`,
        params
      );
      setWordState((wordstate) => ({ ...wordstate, wordlist: response.data }));
    };
    getData();
  }, [
    wordstate.type,
    wordstate.wordcategory,
    wordstate.dispcount,
    setWordState,
  ]);

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>単語</TableCell>
            <TableCell>回数</TableCell>
            <TableCell>品詞</TableCell>
            <TableCell>英語</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {wordstate.wordlist.map((row, index) => (
            <TableRow key={row.index}>
              <TableCell>
                {row.word.toLowerCase()}
              </TableCell>
              <TableCell>{row.cnt}</TableCell>
              <TableCell>
                {row.category}
              </TableCell>
              <TableCell>
                {row.meaning_en}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WordTable;
