import React, { useContext } from "react";
import { PlayerNationContext } from "../context/PlayerNationContext";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

export default function PlayerNationSumDispCount() {
  const [playerstate, setPlayerState] = useContext(PlayerNationContext);

  const handleChange = (event, value) => {
    setPlayerState((playerstate) => ({
      ...playerstate,
      dispcountsum: Number(value),
    }));
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={String(playerstate.dispcountsum)}
      exclusive
      onChange={handleChange}
      aria-label="text alignment"
    >
      <ToggleButton value="10" aria-label="Top10">
        Top10
      </ToggleButton>
      <ToggleButton value="20" aria-label="Top20">
        Top20
      </ToggleButton>
      <ToggleButton value="100" aria-label="Top50">
        All
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
