import React, { useContext } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { WordContext } from "./context/WordContext";
import { YoutubeContext } from "./context/YoutubeContext";
import { YoutubeIDContext } from "./context/YoutubeIDContext";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

const MusicTBtn = () => {
   const [wordstate, setWordState] = useContext(WordContext);
   const [state, setState] = useContext(YoutubeContext);
   const [idstate, setIDState] = useContext(YoutubeIDContext);
   const history = useHistory();

    const handleChange = (event, nation) => {
      setState((state) => ({
        ...state,
        nation: nation,
      }));
    };
  const handleChange2 = (event, eventKey) => {
    setWordState((wordstate) => ({ ...wordstate, type: eventKey }));
    setState((state) => ({ ...state, type: eventKey }));
    setIDState({
      time: new Date(),
      id: 0,
    });
    history.push("/music/" + eventKey);
    console.log(eventKey);
  };
    
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <ToggleButtonGroup
              size="small"
              value={state.nation}
              exclusive
              onChange={handleChange}
              aria-label="nation"
            >
              <ToggleButton value="イタリア" aria-label="イタリア">
                イタリア
              </ToggleButton>
              <ToggleButton value="スペイン" aria-label="スペイン">
                スペイン
              </ToggleButton>
              <ToggleButton value="ドイツ" aria-label="ドイツ">
                ドイツ
              </ToggleButton>
              <ToggleButton value="イングランド" aria-label="イングランド">
                イングランド
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              size="small"
              value={state.type}
              exclusive
              onChange={handleChange2}
              aria-label="genre"
            >
              {state.nation_type[state.nation].map((type, index) => (
                <ToggleButton value={type} aria-label={type}>
                  {type}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </div>
    );
};

export default MusicTBtn;
