import React, { useEffect, useContext } from "react";
import { JShootContext } from "./context/JShootContext";
import Select from "react-select";
import axios from "axios";

const options = [];

const JShootSelect = () => {
  const [state, setState] = useContext(JShootContext);

  useEffect(() => {
    const getData = async () => {
      let params = new URLSearchParams();
      params.append("type", "チーム名");
      params.append("type2", "");
      params.append("year", "");
      params.append("division", "");
      params.append("team", "");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/getData.php`,
        params
      );
      response.data.map((row) =>
        options.push({ value: row["team_id"], label: row["ShortName"] })
      );
    };
    getData();
  }, []);

  const handleChange = (selectedOption) => {
    // debugger;
    if (selectedOption !== null && selectedOption.length > 2) {
      selectedOption.pop();
    } else if (selectedOption !== null && selectedOption.length === 2) {
      // debugger;
      setState(selectedOption);
      // setState({ team1: selectedOption[0].value, team2: selectedOption[1].value });
    }
  };

  return (
    <div>
      <Select
        closeMenuOnSelect={false}
        // defaultValue={options[0]}
        isMulti
        name="colors"
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChange}
        placeholder="2チーム選択"
        isSearchable={false}
      />
    </div>
  );
};

export default JShootSelect;
